
  document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('.carousel')) {
      let script = document.createElement('script');
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/flickity/3.0.0/flickity.pkgd.min.js";
      script.defer = true; // Chargement différé
      document.body.appendChild(script);
    }
  });

